import SimplifiedLayout from "@/components/layout/SimplifiedLayout";
import NotFoundSection from "@/components/sections/NotFound";

function ThankYouPage() {
  const seo = { title: "Page Not Found" };

  return (
    <SimplifiedLayout seo={seo} type="website">
      <NotFoundSection />
    </SimplifiedLayout>
  );
}

export default ThankYouPage;
