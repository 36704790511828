import clsx from "clsx";

import Button from "@/components/elements/Button";
import LinkComponent from "@/components/elements/LinkComponent";
import Heading from "@/components/elements/Heading";
import soyBeansBgImage from "@/assets/images/soybeans.png";

import * as styles from "./styles.module.scss";

export function NotFoundSection() {
  return (
    <section className={styles.wrapper}>
      <img src="/svgs/leaf.svg" alt="Leaf Pattern" className={styles.leafPattern} />
      <div className={clsx("container", styles.flex)}>
        <img
          src={soyBeansBgImage}
          alt="Soy Beans as Background"
          className={styles.soyBeansPattern}
        />
        <div className={styles.contentWrap}>
          <p className={styles.label}>404</p>
          <Heading level={1}>
            Something went <br /> <span> wrong!</span>
          </Heading>
          <p>The page you were looking for probably doesn’t exist.</p>
          <Button variant="primary">
            <LinkComponent
              url="/"
              type="internal"
              title="Go back to home page"
            />
          </Button>
        </div>
      </div>
    </section>
  );
}
