import LinkComponent from "@/components/elements/LinkComponent";
import * as styles from "./styles.module.scss";

export function SimplifiedHeader({ logo }: { logo: StoryblokAsset }) {
  return (
    <header className={styles.menuWrapper}>
      <div className="container">
        <LinkComponent url="/" type="internal" className={styles.logo}>
          <img src={logo?.filename} alt={logo?.alt} />
        </LinkComponent>
      </div>

      <img src="/svgs/header-bg.svg" alt="header bg" data-svg="true" className={styles.bgPattern} />
      <img src="/svgs/navbarMobileBg.svg" alt="background" data-svg="true" className={styles.bgPatternMobile} />
    </header>
  );
}
